import useLivlyUser from "@/context/UserProvider";

import { Task } from "@/types/User";
import { useEffect, useState } from "react";
import { Modal } from "./Dialog";
import axios from "axios";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import { useQuery } from "@tanstack/react-query";
import ThankYou from "./Feedback/FeedbackThankYou";
import { Spinner } from "./Spinner";
import FeedbackForm from "./Feedback/FeedbackForm";
import { usePostResidentSentiment } from "./Feedback/usePostFeedback";
import FeedbackRatingForm from "./Feedback/FeedbackRatingForm";
import {
  defineFeedbackFormOptions,
  defineFeedbackRatingFormOptions,
  Feedback,
  FeedbackRequest,
} from "./Feedback/feedback";

export default function ResidentSentimentTask({
  task,
}: {
  task: Task;
  leaseId: number;
}) {
  const { user } = useLivlyUser();
  const feedbackId = task.metaData?.feedbackId as number;
  const {
    reviewLocations,
    showThankYou,
    isVisible,
    isModalOpen,
    feedback,
    onSetFeedback,
    setIsVisible,
    setIsModalOpen,
    onCloseThankYou,
    onSubmit,
    isLoading,
  } = usePostResidentSentiment(feedbackId);
  const { title, subtitle } = defineFeedbackRatingFormOptions(task, user);

  return (
    <div>
      <FeedbackRatingForm
        title={title}
        subtitle={subtitle}
        onSubmit={(rating) => {
          onSetFeedback({ rating });
          setIsModalOpen(true);
        }}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      {isModalOpen && (
        <SubmitFeedbackModal
          feedbackId={feedbackId}
          feedback={feedback}
          setFeedback={onSetFeedback}
          selectedRating={feedback?.rating}
          onSubmit={onSubmit}
          isSubmitting={isLoading}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <ThankYou
        open={showThankYou}
        onClose={onCloseThankYou}
        reviewLocations={reviewLocations}
        buildingName={user.building?.name}
      />
    </div>
  );
}

async function getFeedbackItem(feedbackId: number) {
  const result = await axios.get<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/resident/feedback/${feedbackId}`
  );

  return result.data.Data;
}

async function updateFeedbackItem(feedback: FeedbackRequest) {
  const result = await axios.put<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/resident/feedback/${feedback.feedbackId}`,
    feedback
  );

  return result.data.Data;
}

function SubmitFeedbackModal({
  selectedRating,
  feedbackId,
  onClose,
  onSubmit,
  isSubmitting,
  feedback,
  setFeedback,
}: {
  selectedRating: number | null;
  feedbackId: number;
  feedback: FeedbackRequest;
  setFeedback: (feedback: Partial<FeedbackRequest>) => void;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (feedbackRequest: FeedbackRequest) => Promise<void>;
}) {
  const { user } = useLivlyUser();

  const { data, isLoading } = useQuery({
    queryKey: ["my-feedback", "item", feedbackId],
    queryFn: () => getFeedbackItem(feedbackId),
  });

  useEffect(() => {
    if (data) {
      setFeedback({
        feedbackId: data.residentFeedbackId,
        rating: selectedRating ?? data.rating,
        comment: data.comment,
        feedbackItems: data.feedbackItems,
      });
    }
  }, [data]);

  const onSetFeedback = (newFeedback: Partial<FeedbackRequest>) => {
    if (feedback) {
      setFeedback({ ...feedback, ...newFeedback });
    }
  };

  const handleSubmit = (feedback: FeedbackRequest) => {
    onSubmit(feedback);
  };

  if (data) {
    const { title, subtitle, ratingTitle, feedbackOptions } =
      defineFeedbackFormOptions(data, feedback, user.buildingName);

    return (
      <Modal open onClose={onClose}>
        <FeedbackForm
          title={title}
          subtitle={subtitle}
          ratingTitle={ratingTitle}
          feedback={feedback}
          feedbackOptions={feedbackOptions ?? []}
          onSetFeedback={onSetFeedback}
          onSubmit={handleSubmit}
          status={isSubmitting ? "loading" : "idle"}
        />
      </Modal>
    );
  }

  return (
    <Modal open onClose={onClose}>
      <div className="flex items-center justify-center h-64">
        <Spinner color="blue" size="xl" />
      </div>
    </Modal>
  );
}
