import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { RESIDENT_SENTIMENT_TASK_KEY } from "./usePostFeedback";
import { RatingIcon } from "../RatingIcon";

type FeedbackRatingFormProps = {
  isVisible: boolean;
  title: string;
  subtitle: string;
  onSubmit: (rating: number) => void;
  setIsVisible: (isVisible: boolean) => void;
};

const variants = {
  open: { opacity: 1, scale: 1, height: "auto" },
  closed: { opacity: 0, scale: 0, height: 0 },
};

export default function FeedbackRatingForm({
  title,
  subtitle,
  isVisible,
  onSubmit,
  setIsVisible,
}: FeedbackRatingFormProps) {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0, height: 0 }}
        animate={isVisible ? "open" : "closed"}
        variants={variants}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <div className="p-4 bg-white rounded-xl shadow-md">
          <div className="flex justify-end">
            <button
              className="text-gray-400"
              onClick={() => {
                setIsVisible(false);
                localStorage.setItem(RESIDENT_SENTIMENT_TASK_KEY, "false");
              }}
            >
              <FontAwesomeIcon icon="times" />
            </button>
          </div>
          <div className="mt-0">
            <div className="flex flex-col items-center space-y-2">
              <h2 className="font-medium text-center">{title}</h2>
              <p className="text-sm text-gray-500">{subtitle}</p>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="grid grid-cols-4 gap-8 mt-8">
              {[0, 1, 2, 3].map((rating) => (
                <label
                  className="flex items-center justify-center"
                  key={rating}
                  onClick={() => {
                    onSubmit(rating);
                  }}
                >
                  <input
                    id="rating"
                    name="rating"
                    type="radio"
                    value={rating.toString()}
                    className="hidden w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <RatingIcon rating={rating} isSelected size="3x" />
                  <span className="hidden ml-4" aria-label={rating.toString()}>
                    {rating}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
