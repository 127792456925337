import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import Alert from "../../components/Alert";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Dialog";
import { FormField, TextArea } from "../../components/Form";
import { RatingIcon } from "../../components/RatingIcon";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BASE_API_URL } from "../../utils/constants";
import { trackMaintenanceFeedbackReview } from "@/utils/analytics";
import toast from "react-hot-toast";
import ThankYou from "@/components/Feedback/FeedbackThankYou";
import { Feedback, ReviewLocation } from "@/components/Feedback/feedback";
import { TicketState } from "@/types/Maintenance";
import useLivlyUser from "@/context/UserProvider";

export const getFeedback = async (feedbackId: number) => {
  const result = await axios.get<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/livly/feedback/${feedbackId}`
  );

  return result.data.Data;
};

function useGetFeedback(feedbackId: number | null) {
  return useQuery(["feedback", feedbackId], () => getFeedback(feedbackId!), {
    refetchOnWindowFocus: false,
    enabled: feedbackId !== null,
  });
}

const postFeedback = async (feedback: FeedbackRequest) => {
  const result = await axios.post<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/livly/feedback/${feedback.feedbackId}`,
    feedback
  );

  return result.data.Data;
};

export const FeedbackSchema = z.object({
  feedbackId: z.number().optional(),
  rating: z.enum(["0", "1", "2", "3"]).optional(),
  comment: z.string().optional(),
  feedbackItems: z.string().array(),
});

export type FeedbackRequest = z.infer<typeof FeedbackSchema>;

export default function MaintenanceTicketRateExperiencePage() {
  const params = useParams<{ leaseId: string; propertyId: string }>();
  const locationState = useLocation().state as {
    feedbackId: number;
    from: string;
  };
  const { user } = useLivlyUser();
  const [showThankYou, setShowThankYou] = useState(false);
  const [reviewLocations, setReviewLocations] = useState<ReviewLocation[]>([]);
  const { feedbackId, from } = locationState;
  const { data, isLoading, isError } = useGetFeedback(feedbackId);
  const { mutate, isLoading: isSaving } = useMutation(postFeedback);
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  let initialValues: FeedbackRequest = {
    feedbackId,
    rating: undefined,
    comment: "",
    feedbackItems: [],
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setTimeout(
      () =>
        navigate(`/lease/${params.leaseId}/maintenance/${params.propertyId}`, {
          state: { status: TicketState.CLOSED },
        }),
      150
    );
  };

  if (!feedbackId) {
    return <Navigate to={from ?? `/lease/${params.leaseId}/maintenance`} />;
  }

  const handleSubmit = (request: FeedbackRequest) => {
    mutate(request, {
      onSuccess: (data) => {
        let score = "";
        switch (request.rating) {
          case "0":
            score = "Low";
            break;
          case "1":
            score = "Medium-Low";
            break;
          case "2":
            score = "Medium-High";
            break;
          case "3":
            score = "High";
            break;
        }

        trackMaintenanceFeedbackReview(
          score,
          request.comment ? request.comment.trim().length > 0 : false
        );

        queryClient.invalidateQueries([
          "maintenance",
          "tickets",
          params.leaseId,
        ]);

        if (data.reviewLocations.length > 0) {
          setOpen(false);
          setShowThankYou(true);
          if (request.rating === "3" && navigator.clipboard) {
            toast.success("Your comment is copied to the clipboard");
            navigator.clipboard.writeText(data.comment);
          }
          setReviewLocations(data.reviewLocations);
        } else {
          toast.success("Rating submitted!");
          navigate(
            `/lease/${params.leaseId}/maintenance/${params.propertyId}`,
            {
              state: { status: TicketState.CLOSED },
            }
          );
        }
      },
      onError: () => {
        alert("Error submitting rating. Please try again.");
      },
    });
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} size="lg" className="z-[100]">
        {isError ? (
          <Alert
            variant="danger"
            message="There was an error loading feedback. Please try again"
          />
        ) : isLoading ? (
          <p>Loading feedback data. Please wait...</p>
        ) : (
          <div className="mt-2">
            <h2 className="text-lg font-bold">
              How’s your experience with this maintenance service?
            </h2>
            <p className="mt-2 font-light text-center">
              Rate your experience below
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={toFormikValidationSchema(FeedbackSchema)}
              onSubmit={handleSubmit}
            >
              {({ values }) => {
                const isNegativeRating = [1, 2].includes(Number(values.rating));
                const feedbackOptions = isNegativeRating
                  ? data?.options.negative
                  : data?.options.positive;

                return (
                  <Form>
                    <FormField htmlFor="rating" label="">
                      <div className="grid grid-cols-4 gap-1">
                        {[0, 1, 2, 3].map((rating) => (
                          <label
                            className="flex items-center justify-center"
                            key={rating}
                          >
                            <Field
                              id="rating"
                              name="rating"
                              type="radio"
                              value={rating.toString()}
                              className="hidden w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                            />
                            <RatingIcon
                              rating={rating}
                              isSelected={rating === Number(values.rating)}
                            />
                            <span
                              className="hidden ml-4"
                              aria-label={rating.toString()}
                            >
                              {rating}
                            </span>
                          </label>
                        ))}
                      </div>
                    </FormField>
                    <AnimatePresence mode="wait">
                      {values.rating ? (
                        <motion.div
                          className="pt-6 mt-6 border-t border-gray-200"
                          initial={{
                            height: 0,
                          }}
                          animate={{
                            height: "auto",
                            opacity: 1,
                            transition: {
                              height: {
                                duration: 0.4,
                              },
                            },
                          }}
                          key="test"
                        >
                          <p className="text-lg font-medium">
                            {isNegativeRating
                              ? "How can your experience be improved?"
                              : " Great! Tell us what can be improved?"}
                          </p>
                          <p className="mt-2">
                            We will pass this information along to your property
                            manager. Select all that apply.
                          </p>
                          <>
                            <FormField
                              htmlFor="feedbackItems"
                              label=""
                              className="mt-2"
                            >
                              <ul>
                                {feedbackOptions.map((option) => (
                                  <li
                                    key={
                                      option.residentFeedbackItemOptionTypeId
                                    }
                                  >
                                    <label className="flex items-center gap-2">
                                      <Field
                                        type="checkbox"
                                        name="feedbackItems"
                                        value={option.residentFeedbackItemOptionTypeId.toString()}
                                      />
                                      <span>{option.description}</span>
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            </FormField>
                          </>

                          <FormField
                            htmlFor="comment"
                            label="Leave us a comment"
                          >
                            <TextArea
                              id="comment"
                              name="comment"
                              className="mt-2"
                            />
                          </FormField>
                        </motion.div>
                      ) : null}
                    </AnimatePresence>

                    <div className="flex justify-end mt-4">
                      <Button
                        size="small"
                        color="primary"
                        type="submit"
                        disabled={isLoading || isSaving || !values.rating}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </Modal>
      <ThankYou
        buildingName={user.building.name}
        open={showThankYou}
        onClose={handleClose}
        reviewLocations={reviewLocations}
      />
    </>
  );
}
