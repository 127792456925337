import { RatingIcon } from "../RatingIcon";
import { AnimatePresence, motion } from "framer-motion";
import { textAreaClass } from "../Form";
import { Button } from "../Button";
import { Feedback, FeedbackOption, FeedbackRequest } from "./feedback";

type FeedbackFormProps = {
  status: "loading" | "idle";
  title: string;
  ratingTitle: string;
  subtitle: string;
  feedback: FeedbackRequest | null;
  feedbackOptions: FeedbackOption[];
  onSetFeedback: (newFeedback: Partial<Feedback>) => void;
  onSubmit: (feedback: FeedbackRequest) => void;
};

export default function FeedbackForm({
  status,
  title,
  ratingTitle,
  subtitle,
  feedback,
  feedbackOptions,
  onSetFeedback,
  onSubmit,
}: FeedbackFormProps) {
  return (
    <div className="mt-4">
      <h2 className="font-medium text-center">{title}</h2>
      <p className="text-sm text-gray-500 text-center mt-2">{subtitle}</p>
      <div className="mt-8">
        <div className="grid grid-cols-4 gap-1">
          {[0, 1, 2, 3].map((rating) => (
            <label
              className="flex items-center justify-center"
              key={rating}
              onClick={() => onSetFeedback({ rating, feedbackItems: [] })}
            >
              <input
                id="rating"
                name="rating"
                type="radio"
                value={rating.toString()}
                className="hidden w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <RatingIcon
                rating={rating}
                isSelected={rating === Number(feedback?.rating)}
                size="3x"
              />
              <span className="hidden ml-4" aria-label={rating.toString()}>
                {rating}
              </span>
            </label>
          ))}
        </div>
        <AnimatePresence mode="wait">
          {feedback?.rating !== null ? (
            <motion.div
              className="pt-6 mt-6 border-t border-gray-200"
              initial={{
                height: 0,
              }}
              animate={{
                height: "auto",
                opacity: 1,
                transition: {
                  height: {
                    duration: 0.4,
                  },
                },
              }}
              key="test"
            >
              {(feedbackOptions ?? []).length > 0 ? (
                <>
                  <p className="text-lg font-medium">
                    {ratingTitle}
                    {/*  {isNegativeRating
                      ? "How can your experience be improved?"
                      : " Great! Tell us what can be improved?"} */}
                  </p>
                  <p className="mt-2">Select all that apply.</p>
                  <ul className="mt-4">
                    {feedbackOptions?.map((option) => (
                      <li key={option.residentFeedbackItemOptionTypeId}>
                        <label className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            name="feedbackItems"
                            checked={feedback?.feedbackItems.includes(
                              option.residentFeedbackItemOptionTypeId
                            )}
                            onChange={() => {
                              if (
                                feedback?.feedbackItems.includes(
                                  option.residentFeedbackItemOptionTypeId
                                )
                              ) {
                                onSetFeedback({
                                  feedbackItems: feedback?.feedbackItems.filter(
                                    (item) =>
                                      item !==
                                      option.residentFeedbackItemOptionTypeId
                                  ),
                                });
                              } else {
                                onSetFeedback({
                                  feedbackItems: [
                                    ...(feedback?.feedbackItems ?? []),
                                    option.residentFeedbackItemOptionTypeId,
                                  ],
                                });
                              }
                            }}
                          />
                          <span>{option.description}</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
              <div className="mt-4">
                <label htmlFor="comment">
                  <p>Leave us a comment</p>
                  <textarea
                    id="comment"
                    name="comment"
                    className={textAreaClass}
                    rows={4}
                    value={feedback?.comment}
                    onChange={(e) => onSetFeedback({ comment: e.target.value })}
                  />
                </label>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>

        <div className="flex justify-end mt-4">
          <Button
            size="small"
            color="primary"
            onClick={() => {
              if (feedback) {
                onSubmit(feedback);
              }
            }}
            disabled={status === "loading" || feedback?.rating === null}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
