import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { Feedback, FeedbackRequest, ReviewLocation } from "./feedback";

export const RESIDENT_SENTIMENT_TASK_KEY = "task-sentiment-visible";

async function postResidentSentiment(
  feedbackId: number,
  feedback: FeedbackRequest
) {
  const result = await axios.post<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/resident/feedback/${feedbackId}`,
    feedback
  );

  return result.data.Data;
}

export function usePostResidentSentiment(feedbackId: number) {
  const queryClient = useQueryClient();
  const residentSentimentFromLocalStorage = localStorage.getItem(
    RESIDENT_SENTIMENT_TASK_KEY
  );
  const [showThankYou, setShowThankYou] = useState(false);
  const [reviewLocations, setReviewLocations] = useState<ReviewLocation[]>([]);
  const [feedback, setFeedback] = useState<FeedbackRequest>({
    feedbackId: 0,
    rating: null,
    comment: "",
    feedbackItems: [],
  });
  const [isVisible, setIsVisible] = useState(
    (residentSentimentFromLocalStorage === null ||
      residentSentimentFromLocalStorage === "true") ??
      true
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const postSentimentMutation = useMutation({
    mutationFn: (sentiment: FeedbackRequest) =>
      postResidentSentiment(feedbackId, sentiment),
  });

  const onSetFeedback = (newSentiment: Partial<FeedbackRequest>) => {
    setFeedback({ ...feedback, ...newSentiment });
  };

  const onSubmit = async (feedback: FeedbackRequest) => {
    return postSentimentMutation.mutate(feedback, {
      onSuccess(data) {
        if (
          data.reviewLocations.length > 0 &&
          feedback.rating === 3 &&
          navigator.clipboard
        ) {
          toast.success("Your comment is copied to the clipboard");
          navigator.clipboard.writeText(data.comment);
          setReviewLocations(data.reviewLocations);
        }

        setIsModalOpen(false);
        setShowThankYou(true);
        setFeedback({
          feedbackId,
          rating: null,
          comment: "",
          feedbackItems: [],
        });
      },
      onError: () => {
        toast.error("An error occurred while submitting your feedback");
      },
    });
  };

  const onSkip = async () => {
    postSentimentMutation.mutate(
      { ...feedback, comment: "" },
      {
        onSuccess(data) {
          if (
            data.reviewLocations.length > 0 &&
            feedback.rating === 3 &&
            navigator.clipboard
          ) {
            toast.success("Your comment is copied to the clipboard");
            navigator.clipboard.writeText(data.comment);
            setReviewLocations(data.reviewLocations);
          }

          setIsModalOpen(false);
          setShowThankYou(true);
        },
        onError: () => {
          toast.error("An error occurred while submitting your feedback");
        },
      }
    );
  };

  const onCloseThankYou = () => {
    setShowThankYou(false);
    queryClient.invalidateQueries({ queryKey: ["lease", "tasks"] });
  };

  return {
    showThankYou,
    reviewLocations,
    onSubmit,
    onSkip,
    isVisible,
    isModalOpen,
    feedback,
    onSetFeedback,
    setIsVisible,
    setIsModalOpen,
    onCloseThankYou,
    isLoading: postSentimentMutation.isLoading,
  };
}
