import useLivlyUser from "@/context/UserProvider";
import PollTask from "@/routes/polls/PollTask";
import { useLeaseTasks } from "./Tasks";
import { TaskAlertType } from "@/types/User";
import ResidentSentimentTask from "./ResidentSentimentTask";

export default function ResidentTasks() {
  const { user } = useLivlyUser();
  const { data } = useLeaseTasks(user.propertyUnitLeaseId);

  const validTasks = data?.filter(
    (task) => task.alertType !== TaskAlertType.InspectionChecklist
  );

  if (validTasks) {
    const [task] = validTasks;

    if (task?.alertType === TaskAlertType.ResidentPoll) {
      return <PollTask leaseId={user.propertyUnitLeaseId} task={task} />;
    }

    if (task?.alertType === TaskAlertType.Feedback) {
      return (
        <ResidentSentimentTask leaseId={user.propertyUnitLeaseId} task={task} />
      );
    }

    if (task?.alertType === TaskAlertType.ResidentSentiment) {
      return (
        <ResidentSentimentTask leaseId={user.propertyUnitLeaseId} task={task} />
      );
    }
  }

  return null;
}
