import { isIOS, isAndroid } from "react-device-detect";

export const APP_VERSION_KEY = "app_version";

export enum APP_VERSION {
  NATIVE = "native",
  XAMARIN = "xamarin",
}

export const isNativeAppVersion = () => {
  // Older native versions are not passing this key along and Xamarin is no longer valid
  // So just check on older key (mobile) for native redirecting
  const appVersion = localStorage.getItem(APP_VERSION_KEY);
  const isNative = localStorage.getItem("mobile") === "true";

  return (
    isNative || (appVersion && appVersion === APP_VERSION.NATIVE ? true : false)
  );
};

export const returnToNative = () => {
  if (isIOS) {
    (
      window as any
    ).webkit?.messageHandlers?.onboardingStepCompleted?.postMessage("");
  } else if (isAndroid) {
    //eslint-disable-next-line
    eval("Android.onboardingStepCompleted()");
  }
};
